// React and Other libraries imports
import React from 'react';
import { isEmpty } from 'lodash';
import { Modal, Form, Row, Col, Button } from 'react-bootstrap';
import { Field } from 'formik';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import moment from 'moment';

// Core components and utils imports
import ValidatedFormInputField from '../../../../../../../core-components/ValidatedFormInputField';
import { obj } from '../../../../../../../utils/commonConstant';

// Styles import
import styles from './AddEditModalPackage.module.scss';

const currentYear = new Date().getFullYear();
const YEARS = Array.from({ length: 61 }, (_, i) => currentYear - 50 + i);

class AddEditModalPackage extends React.Component {
  constructor(props) {
    super(props);
  }

  makeBtnEnable = () => {
    const { universityName, registrationNumber, startDate, endDate, educationLevel } =
      this.props.values;

    return universityName &&
      registrationNumber &&
      startDate &&
      endDate &&
      moment(startDate) <= moment(endDate) &&
      moment(startDate) <= moment() &&
      educationLevel
      ? true
      : false;
  };

  render() {
    const { handleSubmit, isSubmitting, values, errors, touched, educationLevel, setFieldValue } =
      this.props;
    const enableBtn = !isSubmitting && isEmpty(errors) && this.makeBtnEnable() ? true : false;
    const degreeType = ['Full Time', 'Part Time', 'Distance'];

    const handleYearChange = (e, fieldname, changeYear, changeMonth) => {
      const SY = e.target.value; // Selected Year
      const CD = new Date(); // Current Date
      const CM = CD?.getMonth(); // Current Month

      let SYM = new Date(
        SY,
        values[fieldname] && values[fieldname] instanceof Date ? values[fieldname].getMonth() : CM
      ); // Selected Year and Month
      if (fieldname === 'startDate') {
        SYM = SYM <= CD ? SYM : new Date(SY, CM); // Setting default month is current month
      } else {
        const SD = values['startDate'] || null; // Start Date
        if (SD) {
          SYM = SYM >= SD ? SYM : new Date(SY, SD?.getMonth());
        }
      }
      setFieldValue(fieldname, SYM);
      changeMonth(SYM?.getMonth());
      changeYear(SY);
    };
    return (
      <Modal
        size='lg'
        show={this.props.show}
        onHide={this.props.onHide}
        dialogClassName='bgvModalDialog'
        className='bgvModal'
        backdropClassName={'customBGVModalBackdrop'}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            <div className={styles.educationModalTitle}>
              <span>
                {this.props.isEdit
                  ? `Edit ${obj[educationLevel]} details`
                  : `Add ${obj[educationLevel]} details`}
              </span>
            </div>
            <div className={styles.educationModalSubTitle}>
              <span>{`Step ${this.props.currentSubStep} of ${this.props.totalStep}`}</span>
            </div>
          </Modal.Title>
        </Modal.Header>

        <Form onSubmit={handleSubmit} className='add-edit-education-form'>
          <Modal.Body className={styles.addEditEducationModalBody}>
            <Row>
              <Col sm={12} xs={12} md={12} lg={12}>
                <Form.Label>
                  College/Institution/School <span>*</span>
                </Form.Label>
                <Field
                  type='text'
                  name='collegeName'
                  component={ValidatedFormInputField}
                  eduCapitalizeName
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12} xs={12} md={12} lg={12}>
                <Form.Label>
                  University/Board <span>*</span>
                </Form.Label>
                <Field
                  type='text'
                  name='universityName'
                  component={ValidatedFormInputField}
                  eduCapitalizeName
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12} xs={12} md={6} lg={6}>
                <Form.Label>
                  Attendance Type <span>*</span>
                </Form.Label>
                <Form.Group className={styles.formGroup}>
                  <Select
                    menuPosition={'fixed'}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                    options={degreeType}
                    value={degreeType?.filter((d) => d === values?.degreeType) || ''}
                    getOptionLabel={(l) => l}
                    getOptionValue={(v) => v}
                    onChange={(value) => {
                      setFieldValue('degreeType', value);
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={12} xs={12} md={6} lg={6}>
                <Form.Label>
                  {'Registration Number'} <span>*</span>
                </Form.Label>
                <Field type='text' name='registrationNumber' component={ValidatedFormInputField} />
              </Col>
            </Row>

            {!['10', '12'].includes(educationLevel) ? (
              <>
                <Row>
                  <Col sm={12} xs={12} md={12} lg={12}>
                    <Form.Label>{'Degree'}</Form.Label>
                    <Field
                      type='text'
                      name='degree'
                      component={ValidatedFormInputField}
                      placeholder='Eg: Bachelor of Technology, Master of Design'
                    />
                  </Col>
                </Row>

                <Row>
                  <Col sm={12} xs={12} md={12} lg={12}>
                    <Form.Label>{'Area of Study'}</Form.Label>
                    <Field
                      type='text'
                      name='areasOfStudy'
                      component={ValidatedFormInputField}
                      placeholder='Eg: Electrical Engineering, Computer Science'
                    />
                  </Col>
                </Row>
              </>
            ) : null}

            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                <Form.Label>
                  Dates Attended<span>*</span>
                </Form.Label>
              </Col>
              <Col
                sm={12}
                xs={12}
                md={6}
                lg={6}
                className={
                  (!!errors.startDate && touched.startDate) ||
                  (values.startDate &&
                    values.endDate &&
                    moment(values.startDate) > moment(values.endDate))
                    ? 'empDatePicker'
                    : ''
                }
              >
                <Form.Group className={styles.formGroupDate}>
                  <DatePicker
                    placeholderText='From'
                    className={styles.datePickerStyle}
                    dateFormat='MM/yyyy'
                    isClearable
                    popperPlacement='auto'
                    minViewMode='months'
                    showMonthYearPicker
                    selected={values.startDate ? moment(values.startDate).toDate() : null}
                    maxDate={new Date()}
                    renderCustomHeader={({ changeYear, changeMonth }) => (
                      <div className={styles.datePickerHeaderStyle}>
                        <select
                          value={
                            values.startDate
                              ? new Date(values.startDate).getFullYear()
                              : new Date().getFullYear()
                          }
                          onChange={(e) => {
                            handleYearChange(e, 'startDate', changeYear, changeMonth);
                          }}
                        >
                          {YEARS.map((option) => {
                            const CD = new Date();
                            const CY = CD.getFullYear();
                            return (
                              <option key={option} value={option} disabled={option > CY}>
                                {option}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    )}
                    onChange={(date) => {
                      setFieldValue('startDate', date);
                    }}
                    onChangeRaw={(date) => {
                      if (new Date(date.currentTarget.value) > new Date()) {
                        setFieldValue('startDate', new Date());
                      }
                    }}
                    popperContainer={(props) => (
                      <div {...props}>
                        {props.children}
                        {!values.startDate && !values.validStartDate && (
                          <div
                            style={{
                              color: '#dc3545',
                              fontSize: '13px'
                            }}
                          >
                            Invalid date. Please select a valid date.
                          </div>
                        )}
                      </div>
                    )}
                  />
                  {values.startDate && moment(values.startDate) > new Date() ? (
                    <div className={styles.errorMsg}>
                      {'Please enter a date that is not in the future.'}
                    </div>
                  ) : values.startDate &&
                    values.endDate &&
                    moment(values.startDate) > moment(values.endDate) ? (
                    <div className={styles.errorMsg}>
                      {'Start date must be before the end date'}
                    </div>
                  ) : null}

                  {!!errors.startDate && touched.startDate && (
                    <div className={styles.errorMsg}>{errors.startDate}</div>
                  )}
                </Form.Group>
              </Col>
              <Col
                sm={12}
                xs={12}
                md={6}
                lg={6}
                className={
                  (!!errors.endDate && touched.endDate) ||
                  (values.startDate &&
                    values.endDate &&
                    moment(values.startDate) > moment(values.endDate))
                    ? 'empDatePicker'
                    : ''
                }
              >
                <Form.Group className={styles.formGroupDate}>
                  <DatePicker
                    key={'endDate'}
                    placeholderText='To (or tentative passing date)'
                    wrapperClassName={styles.datePickerWrapper}
                    className={styles.datePickerStyle}
                    dateFormat='MM/yyyy'
                    isClearable
                    popperPlacement='auto'
                    minViewMode='months'
                    showMonthYearPicker
                    selected={values.endDate ? moment(values.endDate).toDate() : null}
                    renderCustomHeader={({ changeYear, changeMonth }) => (
                      <div className={styles.datePickerHeaderStyle}>
                        <select
                          value={
                            values.endDate
                              ? new Date(values.endDate).getFullYear()
                              : new Date().getFullYear()
                          }
                          onChange={(e) => handleYearChange(e, 'endDate', changeYear, changeMonth)}
                        >
                          {YEARS.map((option) => {
                            const SD = new Date(values?.startDate) || null;
                            const SY = SD?.getFullYear() || null;
                            return (
                              <option key={option} value={option} disabled={SY && option < SY}>
                                {option}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    )}
                    onChange={(date) => setFieldValue('endDate', date)}
                    popperContainer={(props) => (
                      <div {...props}>
                        {props.children}
                        {!values.endDate && !values.validEndDate && (
                          <div
                            style={{
                              color: '#dc3545',
                              fontSize: '13px'
                            }}
                          >
                            Invalid date. Please select a valid date.
                          </div>
                        )}
                      </div>
                    )}
                  />

                  {values.startDate &&
                  values.endDate &&
                  moment(values.startDate) > moment(values.endDate) ? (
                    <div className={styles.errorMsg}>{'End date must be after the start date'}</div>
                  ) : null}
                  {!!errors.endDate && touched.endDate && (
                    <div className={styles.errorMsg}>{errors.endDate}</div>
                  )}
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>

          <Modal.Footer className={styles.educationSubmitBtn}>
            <Button
              className={'bgvModalSecondaryBtn'}
              onClick={(e) => {
                this.props.onHide();
              }}
            >
              {'Cancel'}
            </Button>
            <Button className={'bgvModalPrimaryBtn'} disabled={!enableBtn} type='submit'>
              {'Next'}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default AddEditModalPackage;
