import React from 'react';
import Image from '../../../../core-components/Image';

const UpsellClubBanner = ({ className }) => {
  return (
    <Image
      onClick={() => window.open('https://2ly.link/20I1N', '_blank')}
      className={className}
      name={'upsellClubBanner.svg'}
    />
  );
};

export default UpsellClubBanner;
